import { Locale as DateFnsLocale } from 'date-fns';
import { CustomLocale } from '../../enums/language-code.enum';

export const dateFnsLocales: Record<
  CustomLocale,
  () => Promise<{ default: DateFnsLocale }>
> = {
  [CustomLocale.EN]: () =>
    import(`@/../node_modules/date-fns/locale/en-US/index.js`),
  [CustomLocale.ES]: () =>
    import(`@/../node_modules/date-fns/locale/es/index.js`),
  [CustomLocale.FR]: () =>
    import(`@/../node_modules/date-fns/locale/fr/index.js`),
  [CustomLocale.AR]: () =>
    import(`@/../node_modules/date-fns/locale/ar/index.js`),
};

const SUPPORTED_LOCALES = Object.values(CustomLocale);

const isSupportedLocale = (input: string): input is CustomLocale =>
  SUPPORTED_LOCALES.includes(input as CustomLocale);

export function getLanguageFromSession(
  language: string,
  pointOfSale: string,
): CustomLocale {
  const languageCodeLowerCase = language.toLowerCase();
  const pointOfSaleLowerCase = pointOfSale.toLowerCase();
  // FIXME : regionalLocale unused at the moment. In anticipation of support for regional languages
  const regionalLocale = `${languageCodeLowerCase}-${pointOfSaleLowerCase}`;

  if (isSupportedLocale(regionalLocale)) {
    return regionalLocale;
  }
  if (isSupportedLocale(languageCodeLowerCase)) {
    return languageCodeLowerCase;
  }
  const firstLanguageCodeMatch = SUPPORTED_LOCALES.find((locale) =>
    locale.startsWith(languageCodeLowerCase),
  );
  return firstLanguageCodeMatch || CustomLocale.EN;
}

export function getLanguageFromBrowser(browserLang: string): CustomLocale {
  const browserLangLowerCase = browserLang.toLowerCase();
  // FIXME : regionalPart unused at the moment. In anticipation of support for regional languages
  const [langPart, regionalPart] = browserLangLowerCase.split('-');

  if (isSupportedLocale(browserLangLowerCase)) {
    return browserLangLowerCase;
  }

  if (isSupportedLocale(langPart)) {
    return langPart;
  }

  const firstLanguageCodeMatch = SUPPORTED_LOCALES.find((locale) =>
    locale.startsWith(langPart),
  );
  return firstLanguageCodeMatch || CustomLocale.EN;
}

/**
 * Expected format for Hyperwallet : 2 letters in lowercase.
 * Eg. 'en'
 */
export function getHWLocaleFromLocale(locale: string): string {
  // Eg. 'en-us' > 'en'
  return locale.toString().slice(0, 2).toLowerCase();
}

export function getLabelFromLocale(
  locale: CustomLocale,
  language: CustomLocale,
): string {
  return (new Intl.DisplayNames([language], { type: 'language' }).of(locale) ||
    '') as string;
}
