<div
  class="layout-container fx-column fx-c-stretch-stretch"
  [dir]="layoutService.getDirection()"
>
  <ng-container *ngIf="!isLoadingConfiguration">
    <app-navbar></app-navbar>
  </ng-container>

  <div
    class="loader-container p-16 fx-column fx-c-center-center"
    [ngStyle]="{
      visibility: isLoadingConfiguration ? 'visible' : 'hidden',
      opacity: isLoadingConfiguration ? '1' : '0',
    }"
  >
    <mat-spinner color="accent"></mat-spinner>
    <p>{{ 'COMMON.LOADING' | translate }}</p>
  </div>

  <div class="content" id="scroll-anchor">
    <router-outlet></router-outlet>
  </div>
</div>
