import { Component, DestroyRef, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../ngrx';
import { getCurrentTheme } from '../../ngrx/global/global.selectors';
import { Theme } from '../../enums/theme.enum';
import { MatToolbar } from '@angular/material/toolbar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [MatToolbar],
})
export class NavBarComponent {
  public currentTheme!: string;

  constructor(private _store: Store<AppState>) {
    // Observables from NgRx store
    this._initNgRxListeners(inject(DestroyRef));
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  // ...

  // -----------------------------------------------
  // Handlers
  // -----------------------------------------------

  public handleImgError(event: Event): void {
    (event.target as HTMLImageElement).style.display = 'none';
  }

  public handleImgLoad(event: Event): void {
    (event.target as HTMLImageElement).style.display = 'initial';
  }

  // -----------------------------------------------
  // Public
  // -----------------------------------------------

  /**
   * Used in order to manage differents img formats (png, svg, ...)
   * @returns
   */
  public getPartnerLogo(): string {
    let logoPath: string = `assets/themes/${this.currentTheme}/logo`;
    let extension: string = '.png';

    switch (this.currentTheme) {
      case Theme.THEME_LIGHT_VOLARIS:
        extension = '.svg';
        break;
    }

    return logoPath + extension;
  }

  // -----------------------------------------------
  // Private
  // -----------------------------------------------

  private _initNgRxListeners(ref: DestroyRef): void {
    this._store
      .pipe(select(getCurrentTheme), takeUntilDestroyed(ref))
      .subscribe((currentTheme: Theme) => (this.currentTheme = currentTheme));
  }
}
