import { Direction } from '@angular/cdk/bidi';
import { DestroyRef, ElementRef, inject, Injectable } from '@angular/core';
import { CustomLocale } from '../enums/language-code.enum';
import { select, Store } from '@ngrx/store';
import { AppState } from '../ngrx';
import { getCurrentLang } from '../ngrx/global/global.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private RTL_LANGUAGES = [CustomLocale.AR];
  private _currentLang!: CustomLocale;

  constructor(private _store: Store<AppState>) {
    // Observables from NgRx store
    this._initNgRxListeners(inject(DestroyRef));
  }

  // -----------------------------------------------
  // Public
  // -----------------------------------------------

  public scrollToTop(
    anchor: ElementRef,
    delayedRendering: boolean = true,
  ): void {
    // Scroll to top of component (timer due to rendering delay)
    setTimeout(
      () => anchor.nativeElement.scrollTo(0, 0),
      delayedRendering ? 1000 : 0,
    );
  }

  public getDirection(): Direction {
    return this.RTL_LANGUAGES.includes(this._currentLang) ? 'rtl' : 'ltr';
  }

  // -----------------------------------------------
  // Private
  // -----------------------------------------------

  private _initNgRxListeners(ref: DestroyRef): void {
    this._store
      .pipe(select(getCurrentLang), takeUntilDestroyed(ref))
      .subscribe(
        (currentLang: CustomLocale) => (this._currentLang = currentLang),
      );
  }
}
